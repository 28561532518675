import './loadingScreen.css'

const styles = () => ({
    loadingScreen: {
        position:'absolute',
        backgroundColor:'rgb(50, 58, 66)',
        width:'100vw',
        height:'100vh',
        zIndex:'50',
        overflow:'hidden',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
})

const DashboardLoading = (props) => {
    const style = styles()
    if (props.loaded === false) {
        return (
            <div style={style.loadingScreen}>
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    } else {
        return (
            null
        )
    }

}

export default DashboardLoading;