import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { userSession } from "../service/session";
import { socket } from "../service/socket";
import './dataSimulation.css'


const HomeThree = () => {
    const [openPlanetMenu, setOpenPlanetMenu] = useState(false)
    const [focusPlanetName, setFocusPlanetName] = useState('')
    const [focusPlanetFullname, setPlanetFullname] = useState('')
    const [focusPlanetExchange, setFocusPlanetExchange] = useState('')
    const [focusPlanetRSI, setFocusPlanetRSI] = useState('')
    const [loadingData,setLoadingData] = useState(false)

    var planets = [];

    const togglePlanetMenu = () => {
        setOpenPlanetMenu(!openPlanetMenu)
    }

    useEffect(() => {
        socket.emit('api/universe/getTopStocks', (data) => {
            planets = data['result'].map(item => {
                return item
            })
            startUniverse();
        })
        return () => {
            socket.off('disconnect');
          }
    }, [])

    const requestStockInfo = name => {
        var sendData = {'token': userSession.token, 'stock': name}
        console.log(sendData)
        socket.emit('api/universe/getStockInfo', sendData, (data) => {
            console.log(data['result'])
            setFocusPlanetName(data['result']['name'])
            setPlanetFullname(data['result']['fullname'])
            setFocusPlanetExchange(data['result']['exchange'])
            setLoadingData(false)
        })
        return () => {
            socket.off('disconnect');
          }
    }

    const mountRef = useRef(null);

    const startUniverse = () => {
        var cont = document.getElementById('simulationID');
        var stars = [];
        var starNum = 0;
        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera( 90, 2, 1, 1000 );
        camera.position.x = 0
        camera.position.y = 0
        camera.position.z = 1
        scene.add(camera)
        var renderer = new THREE.WebGLRenderer();

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        const setMouse = (event) => {
            mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        }

        const onMouseClick = (event) => {
            setMouse(event)
            raycaster.setFromCamera( mouse, camera );
            const intersects = raycaster.intersectObjects( scene.children );
            if (intersects.length > 0) {
                var obj = intersects[0]
                obj.object.material.color.set( 0xff0000 );
                setLoadingData(true)
                setFocusPlanetRSI(obj.object.userData.rsi)
                requestStockInfo(obj.object.userData.name);
                setOpenPlanetMenu(true)
            }

        }

        window.addEventListener('click', onMouseClick, false)

        mountRef.current.appendChild( renderer.domElement );

        // Controls
        const controls = new OrbitControls(camera, cont)
        controls.target.set( 0, 0, 0 ); // IMPORTANT: new location of focus
        controls.enableDamping = true

        // Lights
        const pointLight = new THREE.AmbientLight(0xffff0f, 500.0)
        pointLight.position.x = 1
        pointLight.position.y = 1
        pointLight.position.z = 4
        scene.add(pointLight)

        const spotLight = new THREE.SpotLight(0xffffff);
        spotLight.position.x = 0
        spotLight.position.y = 0
        spotLight.position.z = 0
        scene.add(spotLight)

        // Stars
        const addStar = () => {    
            if (planets[starNum] !== undefined){
                var planetData = planets[starNum].map(item => {
                    return item
            })}
            const geom = new THREE.SphereGeometry((planetData[1]/100) * .5,10,10);
            const mat = new THREE.MeshStandardMaterial( {color: 0xffffff})
            const star = new THREE.Mesh(geom,mat)
            star.userData = {
                name: planetData[0],
                rsi: planetData[1]
            }
            starNum++;
            const [x,y,z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(100));
            star.position.set(x,y,z);
            scene.add(star)
            stars.push(star)
        }

        const animate = () => {
            onWindowResize();       
            // Update Orbital Controls
            controls.maxDistance = 400;
            controls.update()
            renderer.render( scene, camera );
            requestAnimationFrame(animate);
        }
        
        Array(400).fill().forEach(addStar);
     
        const onWindowResize = () => {
            const width = cont.clientWidth;
            const height = cont.clientHeight;

            if (cont.width !== width || cont.height !== height) {
                renderer.setSize(width,height,false);
                camera.aspect= width / height;
                camera.updateProjectionMatrix()
            }
        }
        requestAnimationFrame(animate);
    };

    const LoadingMenu = () => {
        return (
            <div className="loadingMenu">
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

    const LoadedMenu = (props) => {
        return (
            <div>
                <button className="planetMenuExit" onClick={togglePlanetMenu} />
                <div className="planetTitle">
                    <p>{props.name}</p>
                </div>
                <div className="planetFullName">
                    <p>{props.fullname}</p>
                </div>
                <div className="planetContent">
                    <p>{props.desc}</p>
                    <p>{props.rsi}</p>
                </div>
            </div>
        )
    }

    const PlanetMenu = () => (
        <div className="menu">
            {loadingData ? <LoadingMenu /> : <LoadedMenu name={focusPlanetName} fullname={focusPlanetFullname} desc={focusPlanetExchange} rsi={focusPlanetRSI}/>}
        </div>
    )
  
    return (
      <div style={{position:"absolute"}} ref={mountRef}>
          {openPlanetMenu ? <PlanetMenu /> : null}
      </div>
    );
}

export default HomeThree;