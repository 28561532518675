import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core';

const CustomSwitch = withStyles({
    root: {
        width: 50,
        height: 20,
        padding: 2,
        marginLeft: 5,
    },
    switchBase: {
        padding:0,
        color: "purple",
        '&$track': {
            backgroundColor:'white',
        },
        '&$checked': {
            transform: 'translateX(30px)',
            color: "white",
            '& + $track': {
              backgroundColor: '#52d869',
              opacity: 1,
              border: 'none',
            },
        },
        '&$checked + $track': {
          backgroundColor: "blue",
        },
      },
      checked: {},
      thumb: {
        width: 20,
        height:20,
        boxShadow: 'none',
      },
      track: {
          border: '1px solid black',
          borderRadius: 20/2,
          opacity: 1,
          backgroundColor:'white',
      },
})(Switch);

const AlgoControls = () => {
    const [checked, setChecked] = React.useState({
        checkedSell: false,
        checkedHold: false,
        checkedStart: true,
    });


    const toggleChecked = (event) => {
        if (event.target.name === "checkedSell") {
            if (checked.checkedSell === true) {
                return;
            }
            checked.checkedStart = false;
            checked.checkedHold = false;
        } else if (event.target.name === "checkedHold") {
            if (checked.checkedHold === true) {
                return;
            }
            checked.checkedStart = false;
            checked.checkedSell = false;
        } else if (event.target.name === "checkedStart") {
            if (checked.checkedStart === true) {
                return;
            }
            checked.checkedHold = false;
            checked.checkedSell = false;
        }
      setChecked({...checked, [event.target.name]: event.target.checked});
    };

    return (
        <div className="algoSliders">
            <p style={{textAlign:'center'}}>Algorithm Controls</p>
            <FormControlLabel
                control={<CustomSwitch 
                    checked={checked.checkedSell} 
                    onChange={toggleChecked} 
                    />}
                label="Stop trading and sell all positions"
                name="checkedSell"
            />
            <FormControlLabel
                control={<CustomSwitch 
                    checked={checked.checkedHold} 
                    onChange={toggleChecked} 
                    />}
                label="Stop trading and hold all positions"
                name="checkedHold"
            />
            <FormControlLabel
                control={<CustomSwitch 
                    checked={checked.checkedStart} 
                    onChange={toggleChecked} 
                    />}
                label="Resume algorithm trading"
                name="checkedStart"
            />
        </div>
    )
}

export default AlgoControls;