import SideMenu from './settingsSideMenu';
import { useState, useEffect } from 'react';
import { socket } from '../service/socket';
import AlpacaConnect from '../oauthConnections/AlpacaConnect';
import { userSession } from '../service/session';

const styleSheet = () => ({
    landingDiv: {
        width:'100vw',
        height:'100vh',
        backgroundColor:'rgb(200,250,125)'
    },
    informationDiv: {
        display:'flex',
        float:'right',
        width:'80%',
        height:'100%',
    },
    profileInfo: {
        position: 'relative',
        left: '8%',
        top: '5%',
        height:'90%',
        width: '40%',
        borderRadius: '30px',
        backgroundColor: 'rgb(100,150,200)',
    },
    connectionInfo: {
        position: 'relative',
        left: '12%',
        top: '5%',
        height:'90%',
        width: '40%',
        borderRadius: '30px',
        backgroundColor: 'rgb(100,100,200)',
    },
    connectionDiv: {
        marginTop:'2%',
        minHeight: '8%',
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    connectionDivTitle: {
        float:'left',
        width:'30%',
        height:'100%',
        textAlign: 'center',
        color: 'white',
        margin: '0px'
    },
    buttonStyle: {
        width: '30%',
        height: '5%',
        backgroundColor: 'rgb(95, 158, 160)',
        border: '0px',
        alignItems:'center',
        margin: '2%',
    },
    oauthButtonsImgs: {
        width: '15%',
        height: 'auto',
        float: 'left',
        left: '5px',
    },
});


const SettingsInformation = () => {
    const styles = styleSheet();

    /*
    This will be verified token whichever form that takes. It will be assumed that it is
    a secure token which only user has and can verify them and pull account information.
    */

    const [UserID,setId] = useState(userSession.token)
    const [UserFirst, setUserFirst] = useState('')
    const [UserLast, setUserLast] = useState('')
    const [UserEmail, setEmail] = useState('')
    const [UserAlpacaId, setAlpacaId] = useState('')
    const [UserGoogle, setGoogle] = useState('')
    const [UserProfilePicture, setUserProfilePicture] = useState('')

    const getUserData = () => {
        socket.emit("api/account/getAccountInfo", UserID, (data) => {
            setEmail(data['result']['email'])
            setAlpacaId(data['result']['alpacaID'])
            setGoogle(data['result']['googleID'])
            setUserFirst(data['result']['first'])
            setUserLast(data['result']['last'])
            setUserProfilePicture(data['result']['profile_img'])
            setId(userSession.token)
        })
    }

    useEffect(() => {
        getUserData()
    })

    return (
        <div style={styles.landingDiv}>
            <SideMenu/>
            <div style={styles.informationDiv}> 
                <div style={styles.profileInfo}>
                    <p>Profile Image: </p>
                    <img src={UserProfilePicture} alt='logged in profile'/>
                    <p>Email: {UserEmail}</p>
                    <p>Password: Secret</p>
                    <p>First Name: {UserFirst}</p>
                    <p>Last Name: {UserLast}</p>
                </div>
                <div style={styles.connectionInfo}>
                    <div style={styles.connectionDiv} id='googleConnection'>
                        <p style={styles.connectionDivTitle}>Google:</p>
                        <p style={styles.connectionDivTitle}>{UserGoogle}</p>
                    </div>
                    <div style={styles.connectionDiv} id='alpacaConnection'>
                        <AlpacaConnect  forSetup={false}/>
                        <p>Alpaca ID: {UserAlpacaId}</p>
                    </div>
                    <div style={styles.connectionDiv} id='fidelityConnection'>

                    </div>
                    <div style={styles.connectionDiv} id='tdameritradeConnection'>

                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default SettingsInformation;