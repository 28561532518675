import React from 'react';
import {Redirect } from 'react-router-dom';
import { userSession } from '../service/session';

const logout = () => {
    userSession.handleSuccessfulLogout()
    return (
        <Redirect to="/" />
    )
}

export default logout;