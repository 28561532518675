import React, { useEffect, useState } from 'react';
import {Button} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import {socket} from '../service/socket';
import alpacaFav from '../videos/loginIcons/alpacaFav.png';
import { userSession } from '../service/session';
const redirect_uri = process.env.REACT_APP_ALPACA_OAUTH_REDIRECT_URI;
const client_idNew = process.env.REACT_APP_ALPACA_OAUTH_CLIENT_ID;

const useStyles = () => ({
    buttonDiv: {
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    buttonDivTitle: {
        float:'left',
        width:'30%',
        height:'100%',
        textAlign: 'center',
        color: 'white',
        margin: '0px'
    },
    buttonStyle: {
        width: '30%',
        height: '5%',
        backgroundColor: 'rgb(95, 158, 160)',
        border: '0px',
        alignItems:'center',
        margin: '2%',
    },
    oauthButtonsImgs: {
        width: '15%',
        height: 'auto',
        float: 'left',
        left: '0px',
    },
});


const AlpacaConnect = (props) => {
    const classes = useStyles();
    const [alpacaConnected,setAlpacaConnected] = useState(false)

    useEffect(() => {
        socket.emit('/api/alpaca/accountConnected', userSession.token , (result) => {
            setAlpacaConnected(result['result'])
        })
    }, [alpacaConnected]);

    async function ConnectToAlpaca() {
        const random_string = Math.random().toString(20)
        const codeURI =
            `https://app.alpaca.markets/oauth/authorize?` +
            `response_type=code&` +
            `client_id=${client_idNew}&` +
            `redirect_uri=${redirect_uri}&` +
            `state=${random_string}&` +
            'scope=data trading account:write'
        try {
            const { code, state } = await openAlpacaPopUp(codeURI)
            console.log(code)
            if (!requestIsValid(random_string, state)) {
                throw new Error("Alpaca Authentication Invalid")
            }
            const dictData = {'alpacaCode': code, 'id': userSession.token}
            socket.emit("/api/alpaca/addAccount", dictData, (data) => {
                console.log(data['result'])
                setAlpacaConnected(data['result'])
            });

        }
        catch (e) {
            const error = {};
            error.message = e.message;
            alert(error);
            console.log(error);
        }

    }

    const requestIsValid = (inital_state, returned_state) => { return inital_state === returned_state }

    const openAlpacaPopUp = (uri) => {
            return new Promise((resolve, reject) => {
                const authWindow = window.open(uri,'Alpaca Superbrain Login','popup=1');
                let snippet = uri | null;

                const interval = setInterval(async () => {
                    try {
                        snippet = authWindow && authWindow.location && authWindow.location.search;
                    } catch (e) { }
                    if (snippet) {
                        const rawCode = snippet.substring(1);
                        const code = JSON.parse('{"' + rawCode.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
                        authWindow.close();
                        resolve(code);
                        clearInterval(interval)
                    }
                }, 100);
            });
        };
    
    const disconnectAlpaca = () => {
        socket.emit('/api/alpaca/removeAccount', userSession.token , (result) => {
            setAlpacaConnected(result['result'])        
        })
    }

    if (props.forSetup === true) {
        if (alpacaConnected) {
            return <Redirect to='/dashboard' />
        } else {
            return (
                <Button onClick={() => ConnectToAlpaca()}>
                    Continue with Alpaca
                </Button>
            )
        }
    }

    if (alpacaConnected) {
        return (
            <div style={classes.buttonDiv}> 
                <p style={classes.buttonDivTitle}>Alpaca Connection: </p>
                <Button onClick={() => disconnectAlpaca()} style={classes.buttonStyle}>
                    <img style={classes.oauthButtonsImgs} src={alpacaFav} alt='alpaca button'/>
                    Disconnect Alpaca
                </Button>
            </div>
        )
    } else {
        return (
            <div style={classes.buttonDiv}>
                <p style={classes.buttonDivTitle}>Alpaca Connection: </p>
                <Button onClick={() => ConnectToAlpaca()} style={classes.buttonStyle}>
                    <img style={classes.oauthButtonsImgs} src={alpacaFav} alt='alpaca button'/>
                    Alpaca Login
                </Button>
            </div>
        )
    }
}

export default AlpacaConnect;