import React, {useEffect, useState} from 'react';
import { userSession } from '../service/session';
import { socket } from '../service/socket';
import './dashboard.css'

const styles = () => ({
    barometerMeter: {
        height: '50px',
        width: '300px',
    }
})

const DashboardBarometer = props => {
    const style = styles();
    const name = 'Barometer'
    const [dataLoaded,setDataLoaded] = useState(false)
    const [algorithmRSIAvgerage, setRSIAverage] = useState([])
    const [algorithmPortfolioRSI, setPortfolioRSI] = useState([])

    useEffect(() => {
        async function getData()  {
            let response = await getBarometerData()
            response = await response
        }
        getData()
    }, [])

    useEffect(() => {
        if (dataLoaded) {
            props.onMount(name)
        }
    }, [dataLoaded])


    const getBarometerData = () => {
        socket.emit('/api/algorithms/rsi/allBarometerData', userSession.token, (data) => {
            setPortfolioRSI([])
            setRSIAverage([])
            setRSIAverage(data['result']['rsi_avg'])
            setPortfolioRSI(data['result']['rsi_portavg'])
            setDataLoaded(true)
        })
    }

    return (
        <div className="barometer contentCard">
            <div style={{float:'left', color:'white'}}>
                <p>Your portfolio: {algorithmPortfolioRSI}</p>
                <meter max='100' value={algorithmPortfolioRSI} style={style.barometerMeter} />
            </div>
            <div style={{float:'right', color:'white'}}>
                <p>All stocks: {algorithmRSIAvgerage}</p>
                <meter max='100' value={algorithmRSIAvgerage} style={style.barometerMeter} />
            </div>

        </div>
    )
}

export default DashboardBarometer;