import ReactDOM from 'react-dom';
import React, {useEffect,useState} from 'react';
import './dashboard.css';
import Navbar from '../userLoggedInNav';
import AlgoControls from '../AccountHelpers/algoControls';
import {socket} from '../service/socket';
import HomeThree from '../threeJS/dataSimulation';
import {userSession} from '../service/session';
import DashboardPortfolio from './dashboardPortfolio';
import DashboardAccountOverview from './dashboardAccountOverview';
import DashboardBarometer from './dashboardBarometer';
import DashboardLoading from './dashboardLoad';



const SimpleDash = (props) => {
        const [brokerageConnected, setBrokerageConnected] = useState(null)
        const [baroLoaded, setBaroLoaded] = useState(false)
        const [summaryLoaded, setSummaryLoaded] = useState(false)
        const [portfolioLoaded, setPortfolioLoaded] = useState(false)
        const [allLoaded, setAllLoaded] = useState(false)

        useEffect(() => {

            if (baroLoaded === true && summaryLoaded === true && portfolioLoaded === true) {
                setAllLoaded(true)
            }
        }, [baroLoaded, summaryLoaded, portfolioLoaded])

        const onComponentMount = name => {
            if (name === 'Barometer') {
                setBaroLoaded(true)
            } else if (name === 'Overview') {
                setSummaryLoaded(true)
            } else if (name === 'Portfolio') {
                setPortfolioLoaded(true)
            } 
        }

        useEffect(() => {
            async function checkIfBrokerageConnected() {
                socket.emit('/api/alpaca/accountConnected', userSession.token, (res) => {
                    setBrokerageConnected(res['result'])
                    if (!brokerageConnected) {
                        setAllLoaded(true)
                    }
                })
            }
            checkIfBrokerageConnected()
        }, [brokerageConnected])


        const expandSimul = () => {
            if (!document.fullscreenElement) {
                let cont = document.getElementById('simulationID');
                ReactDOM.findDOMNode(cont).requestFullscreen();   
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
            }
        }

        


        return (
            <div className="landingPage" style={{backgroundColor: "rgb(50, 58, 66)"}}>
                    <DashboardLoading key={allLoaded} loaded={allLoaded}/>
                    <Navbar />
                    <DashboardAccountOverview onMount={onComponentMount}/>
                    <DashboardPortfolio onMount={onComponentMount}/>
                    <DashboardBarometer onMount={onComponentMount}/>
                    <div className="stockSimulation contentCard" >
                            <div className='dataSimulation' id='simulationID'>
                                <button onClick={() => expandSimul()} className="simulButton" id="simulButtonid"/>
                                <HomeThree />
                            </div>

                    </div>
                    <div className="algoControls contentCard">
                        <div className="algoCont">
                            <AlgoControls />
                        </div>
                    </div>
                    <div className="algoAccountTransactions contentCard">
                    </div>
            </div>
        )
}



export default SimpleDash;