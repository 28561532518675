import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './dashboard/dashboard';
import notFound from './404';
import Login from './accountPages/login';
import mainPage from './mainPage';
import logout from './accountPages/logout';
import createAccount from './accountPages/signUp';
import InformationSettings from './settingsFiles/informationSettings';
import ConnectionsSettings from './settingsFiles/connectionsSettings';
import SettingsPayment from './settingsFiles/paymentSettings';
import SettingsAbout from './settingsFiles/aboutSettings';
import SettingsSupport from './settingsFiles/supportSettings';
import AccountSetup from './accountPages/accountSetup';
import {userSession} from '../src/service/session';

const AppRouter = () => {
    const [loading,setLoading] = useState(true)


    useEffect(() => {
        const returnFromUser = userSession.checkForSessionInSessionStorage();
        if (returnFromUser) {
            setLoading(false)
        }
        
    }, [])

    if(loading) {
        return (
            <div>
                <h1>Loading from session storage</h1>
            </div>
        )
    }

    return (
            <Router>
                <Switch>
                    <Route exact path='/' component={mainPage}/>

                    <Route exact path='/login' render={() => (
                        userSession.status === 'unauthenticated' ? 
                        <Login /> : <Redirect to='dashboard' />
                    )} />
                    <Route exact path='/logout' component={logout} />
                    <Route exact path='/createAccount' component={createAccount} />
                    <Route exact path='/dashboard' component={Dashboard}/>
                    <Route exact path='/settings' component={InformationSettings} />
                    
                    <Route exact path='/settings/information' component={InformationSettings} />
                    <Route exact path='/settings/connections' component={ConnectionsSettings} />
                    <Route exact path='/settings/billing' component={SettingsPayment} />
                    <Route exact path='/settings/support' component={SettingsSupport} />
                    <Route exact path='/settings/about' component={SettingsAbout} />
                    <Route exact path='/account/setup' component={AccountSetup} />
                    <Route component={notFound} />
                </Switch>
            </Router>
    )
    }


export default AppRouter;