import { useEffect, useRef } from "react";
import * as THREE from "three";


const SignupThree = () => {

    const mountRef = useRef(null);
    var stars = [];
    var velocity = 0;

    useEffect(() => {
  
        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
        scene.add(camera)
        var renderer = new THREE.WebGLRenderer();
    
        renderer.setSize( window.innerWidth-1, window.innerHeight-1 );
        mountRef.current.appendChild( renderer.domElement );

        // Lights
        const pointLight = new THREE.PointLight(0xffffff, 0.9)
        pointLight.position.x = 1
        pointLight.position.y = 1
        pointLight.position.z = 4
        scene.add(pointLight)

        // Stars
        const addStar = () => {    
            const geom = new THREE.SphereGeometry(.1,16,16);
            const mat = new THREE.MeshStandardMaterial( {color: 0xffffff})
            const star = new THREE.Mesh(geom,mat)

            const [x,y,z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(200));

            star.position.set(x,y,z);
            scene.add(star)
            stars.push(star)
        }

        const moveStar = () => {
            requestAnimationFrame( moveStar );
            for (let index = 0; index < stars.length; index++) {
                const element = stars[index];
                element.position.z += velocity;
                if (velocity < 2.0) {
                    velocity = velocity + .00001;
                }
                camera.rotateY += .5;
                if (element.position.z > 100) element.position.z = -100;
            }
            renderer.render( scene, camera );
        }
        
        Array(1000).fill().forEach(addStar);
     
    
        let onWindowResize = function () {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize( window.innerWidth, window.innerHeight );
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        }
  
        window.addEventListener("resize", onWindowResize, false);
    
        moveStar();

    }, []);
  
    return (
      <div style={{position:"absolute"}} ref={mountRef}>
  
      </div>
    );
}

export default SignupThree;