import React, {useEffect,useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import {socket} from '../service/socket';
import {userSession} from '../service/session';
import './dashboard.css';

const DashboardAccountOverview = props => {
    const name = 'Overview'
    const [dataLoaded,setDataLoaded] = useState(false)
    const [accountVal, setAccountVal] = useState("");
    const [accountCOH, setAccountCOH] = useState("");
    const [accountBuyingPower, setAccountBuyingPower] = useState("");

    useEffect(() => {
        async function getData()  {
            let response = await refreshAccountInfo()
            response = await response
        }
        getData()
    }, [])

    useEffect(() => {
        if (dataLoaded) {
            props.onMount(name)
        }
    }, [dataLoaded])

    const refreshAccountInfo = () => {
        getAccountValue()
    }

    const getAccountValue = () => {
        socket.emit("/account/accountSummary", userSession.token, (data) => {
            setAccountVal(data['result']['portfolio_value'])
            setAccountBuyingPower(data['result']['buying_power'])
            setAccountCOH(data['result']['cash'])
            setDataLoaded(true)
        });
    }


    if (dataLoaded) {
    return (
        
        <div className="accountData contentCard">
            <div className="accountNumbers">
                <p>Hello {userSession.Session.user.name}!</p>
                <p>Account Value: {accountVal} </p>
                <p>Cash: {accountCOH}</p>
                <p>Buying Power: {accountBuyingPower}</p>
            </div>
            <div className="accountRefresh">
                <IconButton onClick={() => refreshAccountInfo()} aria-label="refresh your account data">
                    <RefreshIcon  />
                </IconButton>
            </div>
        </div>
    )
    }
    return null
}

export default DashboardAccountOverview;