import { useEffect, useRef } from "react";
import * as THREE from "three";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

const LoginThree = () => {

    const mountRef = useRef(null);

    useEffect(() => {
        var cont = document.getElementById('synapseCont');
        var scene = new THREE.Scene();
        var camera = new THREE.OrthographicCamera( -window.innerWidth/2, window.innerWidth/2,window.innerHeight/2,-window.innerHeight/2,0.1,1000);
        camera.position.x = 0;
        camera.position.y = 0;
        camera.position.z = 80;
        scene.add(camera)
        var renderer = new THREE.WebGLRenderer();
        renderer.setClearColor("rgb(0,0,0)")
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight);

        const composer = new EffectComposer(renderer);
        const renderPass = new RenderPass(scene,camera);
        composer.addPass(renderPass);

        scene.add( new THREE.AmbientLight( 0x404040 ) );

        const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
			bloomPass.threshold = .3;
			bloomPass.strength = 4;
			bloomPass.radius = 0;
        
        //composer.addPass(bloomPass);

        mountRef.current.appendChild( renderer.domElement );

        var sparkles = []
        var minSparkles = 5
        var maxSparkles = 10

        var nodes = [];
        var lines = [];
        var numNodes = 50;
        var minDist = 100;
        var spring = 0.0001;

        const setup = () => {
            checkNodeNum();
            createNodes();

            window.addEventListener("resize", onWindowResize, false);
        }

        const createNodes = () => {
            const darkPurple = "rgb(105,0,173)"
            const lightPurple = "rgb(149,0,255)"
            for (let i = 0; i < numNodes; i++) {
                var randomNum = Math.floor(Math.random() * 2)
                const geometry = new THREE.SphereGeometry(45.0,100,100);
                var material = null
                if (randomNum === 1) {
                    material = new THREE.MeshBasicMaterial( { color:  new THREE.Color(darkPurple) });
                } else {
                    material = new THREE.MeshBasicMaterial( { color:  new THREE.Color(lightPurple) });
                }
                const circle = new THREE.Mesh( geometry, material );
                circle.position.x = Math.random() * (window.innerWidth/2 + window.innerWidth/2) - window.innerWidth/2;
                circle.position.y = Math.random() *(window.innerHeight/2 + window.innerHeight/2) - window.innerHeight/2;
                circle.position.z = 0;

                circle.velX = Math.random()*4-2;
                circle.velY = Math.random()*4-2;
                scene.add( circle );
                nodes.push(circle)
            }
        }

        const render = () => {
            requestAnimationFrame( render );
            removeLines();
            updateNodes();
            //removeSparkes();
            //updateSparkles();
            composer.render();
        }

        const removeLines = () => {
            if (lines.length > 0) {
                lines.forEach(line => {
                    line.geometry.dispose();
                    line.material.dispose();
                    scene.remove(line);
                });
            }
        }

        const removeNodes = () => {
            if (nodes.length > 0) {
                nodes.forEach(node => {
                    node.geometry.dispose();
                    node.material.dispose();
                    scene.remove(node);
                })
            }
        }

        const removeSparkes = () => {
            var time = Date.now()
            if (sparkles.length > 0) {
                sparkles.forEach(function(e,index,object) {
                    if (time - e[1] > 3000) {
                        e[0].geometry.dispose();
                        e[0].material.dispose();
                        scene.remove(e[0])
                        object.splice(index,1)
                    }
                });
            }
        }

        const updateNodes = () => {
            lines = []
            for (let index = 0; index < nodes.length; index++) {
                const node = nodes[index];
                node.position.x += node.velX;
                node.position.y += node.velY;
                if (node.position.x > window.innerWidth/2) {
                    node.position.x = -window.innerWidth/2;
                } else if (node.position.x < -window.innerWidth/2) {
                    node.position.x = window.innerWidth/2;
                }
                if (node.position.y > window.innerHeight/2) {
                    node.position.y = -window.innerHeight/2;
                } else if (node.position.y < -window.innerHeight/2) {
                    node.position.y = window.innerHeight/2;
                }

                for (let j = index + 1; j < numNodes; j++) {
                    const next = nodes[j];

                    var dx = node.position.x - next.position.x;
                    var dy = node.position.y - next.position.y;
                    var d = Math.sqrt(dx * dx + dy * dy);

                    if (d < minDist) {
                        var alphaVal = (1.5 - (d / minDist));

                        var points = [];
                        points.push(new THREE.Vector3(node.position.x,node.position.y,0));
                        points.push(new THREE.Vector3(next.position.x,next.position.y,0));
                        const geom = new THREE.BufferGeometry().setFromPoints(points);
                        //rgb(242,240,108)   rgb(45,245,190) 7DEDFF
                        const material = new THREE.LineBasicMaterial( { color:  new THREE.Color("rgb(45,245,190)"), transparent: true, opacity: alphaVal } );
                        const line = new THREE.Line(geom,material);
                        scene.add(line);
                        lines.push(line);
                        
                        var ax = dx * spring;
                        var ay = dy * spring;
                        node.velX += ax;
                        node.velY += ay;
                        next.velX -= ax;
                        next.velY -= ay;
                    }
                }
            }
        }

        const updateSparkles = () => {
            if (sparkles.length < 30){
                var numsparkes = Math.random(minSparkles,maxSparkles)
                const WhiteColor = "rgb(255,255,255)"
                for (let i = 0; i < numsparkes; i++) {
                    const geometry = new THREE.SphereGeometry(5.0,20,20);
                    var material = null
                    material = new THREE.MeshBasicMaterial( { color:  new THREE.Color(WhiteColor) });
                    
                    const circle = new THREE.Mesh( geometry, material );
                    circle.position.x = Math.random() * (window.innerWidth/2 + window.innerWidth/2) - window.innerWidth/2;
                    circle.position.y = Math.random() *(window.innerHeight/2 + window.innerHeight/2) - window.innerHeight/2;
                    circle.position.z = 50;

                    circle.velX = Math.random()*4-2;
                    circle.velY = Math.random()*4-2;
                    scene.add( circle );
                    sparkles.push([circle,Date.now()])
                }
            }
        }

        const checkNodeNum = () => {
            var widthHold = cont.clientWidth;
            if (widthHold < 400) {
                numNodes = 10;
                minDist = 75;
                removeNodes();
                nodes = []
                createNodes();
            } else if (widthHold < 800) {
                numNodes = 25;
                minDist = 75;
                removeNodes();
                nodes = []
                createNodes();
            } else if (widthHold <  1200) {
                numNodes = 40;
                minDist = 75;
                removeNodes();
                nodes = []
                createNodes();
            } else if (widthHold < 1500) {
                numNodes = 10;
                minDist = 300;
                removeNodes();
                nodes = []
                createNodes();
            } else {
                numNodes = 15;
                minDist = 300;
                removeNodes();
                nodes = [];
                createNodes();
            }
        }
     
        const onWindowResize = () => {
            const width = cont.clientWidth;
            const height = cont.clientHeight;
            if (cont.width !== width || cont.height !== height) {
                renderer.setSize(width,height,true);
                renderer.setPixelRatio( window.devicePixelRatio );
                camera.left =-window.innerWidth/2;
                camera.right = window.innerWidth/2;
                camera.top = window.innerHeight/2;
                camera.bottom = -window.innerHeight/2; 
                camera.updateProjectionMatrix();  
                checkNodeNum();             
            }
        }
  
        setup();
        render();
  
    }, []);
  
    return (
      <div style={{position:"absolute"}} ref={mountRef} />
    );
}

export default LoginThree;