import {socket } from '../service/socket'

class UserSession {
    constructor() {
        this.Session = undefined;
        this.status = 'unauthenticated';
        this.token = undefined;
    }

    checkForSessionInSessionStorage() {
        const sesh = JSON.parse(window.sessionStorage.getItem('userSession'))
        if (sesh != null) {
            console.log(sesh.token)
            socket.emit('/api/account/verifyAccountToken', sesh['token'], (data) => {
                if (data['result']) {
                    this.Session = sesh['Session']
                    this.token = sesh['token']
                    this.status = sesh['status']
                } 
                return true
            })
        }
        return true
    }

    handleSuccessfulLogin(session) {
        console.log(session)
        this.Session = {
            user: {
                name: session['user']['name'],
                email: session['user']['email'],
                image: session['user']['image']
            },
        }
        this.token = session['token']
        this.status = 'authenticated'
        const sesh = {
            Session: this.Session,
            token: this.token,
            status: this.status
        }
        console.log(sesh)
        window.sessionStorage.setItem('userSession', JSON.stringify(sesh))
    }

    handleUnsuccessfulLogin() {
        this.Session = null;
        this.token = null;
        this.status = 'unauthenticated'
    }

    handleSuccessfulLogout() {
        window.sessionStorage.removeItem('userSession')
        this.Session = undefined;
        this.token = undefined;
        this.status = 'unauthenticated'
    }

    LogSession() {
        console.log(this.Session)
    }

}

export let userSession = new UserSession();