import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import image from '../videos/logos/Favicon.png'
import SignupThree from '../threeJS/signupThreeScene';
import GoogleConnect from '../oauthConnections/GoogleConnect'


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: '100vh',
    display: 'flex',
    
  },
  image: {
    height: '100vh',
    width: '100vw',
    display: "flex",
  },
  signup: {
    zIndex: "1",
    height: '55%',
    width: '30%',
    minWidth: "230px",
    minHeight: "500px",
    borderRadius: 10,
    backgroundColor: 'rgb(50, 58, 66)',
    position: "fixed",
    top: "50%",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '50px',
    height: '50px',
  },
  form: {
    width: '70%', // Fix IE 11 issue.

  },
  submit: {
    margin: '3% 0 1% 0',
    backgroundColor: 'rgb(40, 59, 122)',
    '&:hover': {
      backgroundColor: 'gray',
    }
  },
  link: {
    margin: '1% 0 3% 0',
    textDecoration: "none",
    color: 'rgb(255,255,255)',
    '&:hover': {
      color: 'gray',
    },
    
  }
}));

export default function SignUp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <SignupThree/>
        <div className={classes.signup}>
          <Avatar className={classes.avatar} src={image} component={Link} to={'/'}/>
          <h3>Sign Up</h3>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>

          </form>
                      
          <Link to="/login" className={classes.link}>
              Already have an account? Sign in
            </Link>
            
          <GoogleConnect />
        </div>
      </div>
    </div>
  );
}