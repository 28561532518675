import React, { useEffect, useState } from 'react';
import './mainPage.css';
import MainSynapse from './threeJS/mainPageSynapse';
import footerLogo from './videos/logos/Symbol-full-colour.png';
import Fade from 'react-reveal/Fade';
import MainLogo from './videos/logos/Symbol-full-colour.png';
import {Link} from 'react-router-dom'
import {socket} from '../src/service/socket'

const MainPage = () => {

    return (
        <div className="landingPage" id="synapseCont">
            <div className='synapseDiv'>
                <MainSynapse />
            </div>

            {/*<div className="topContent">
                <div className="navBar">
                    <Navbar style={{zIndex:"5"}}> </Navbar>
                </div>
                <div className="titleHook" style={{textAlign:'center'}}>
                    <img src={largeLogo} alt="Main Page Superbrain Securities Logo" style={{ width:"40%",height:"40%",position:"absolute",top:"50%",left:"50%",marginRight:"-50%",transform: "translate(-50%,-60%)", minWidth: "250px",minHeight:'125px'}}/>
                    <div className='downArrowContainer'>
                        <div className='downArrowTop bounce' />
                        <div className='downArrowBottom bounce' />
                    </div>
                </div>
            </div>
            <div className="featurePanel">
                <div className='inverseBorder'><div className='cutoutLeft easyHide'></div><div className='cutoutRight easyHide'></div></div>
                <div className="centerInfo">
                    <div className='whoWeAre'>
                        <h3>We here at Superbrain Securities believe making money in the market shouldn't be rocket science. 
                            This is why we strive to create simple to use trading services that  maximize your returns.
                        </h3>
                    </div>
                    <div className='features'>
                        <div className="featCard">
                            <img src={eco} alt="Algorithm Feature Description" className='featCardImg'/>
                            <div className='featText'>
                                <p>Combining advanced algorithms and machine learning allows us to pick the best stocks</p>
                            </div>
                            <a href='#algoDetails'><button className="featCardButton">Learn More</button></a>
                        </div>
                        <div className='featCard'>
                            <img src={universe} alt="Simulation Feature Description" className='featCardImg'/>
                            <div className='featText'>
                                <p>Interactive simulation to show the stocks that show the most potential</p>
                            </div>
                            <a href='#simulDetails'><button className="featCardButton">Learn More</button></a>
                        </div>
                        <div className='featCard'>
                            <img src={control} alt="Control Feature Description" className='featCardImg'/>
                            <div className='featText'>
                                <p>Easy to use controls that put you in charge</p>
                            </div>
                            <a href='#controlAndGainDetails'><button className="featCardButton">Learn More</button></a>
                        </div>
                        <div className='featCard'>
                            <img src={money} alt="Payment Feature Description" className='featCardImg'/>
                            <div className='featText'>
                                <p>Only pay if you gain. If you don't win, neither do we.</p>
                            </div>
                            <a href='#controlAndGainDetails'><button className="featCardButton">Learn More</button></a>
                        </div>
                        <div className='featCard'>
                            <img src={money} alt="Brokerage Connect Feature Description" className='featCardImg'/>
                            <div className='featText'>
                                <p>Connect with your favorite brokerage. None of your money ever goes through us.</p>
                            </div>
                            <a href='#brokerageDetails'><button className="featCardButton">Learn More</button></a>
                        </div>
                        <div className='featCard'>
                            <img src={control} alt="Brokerage Security Feature Description" className='featCardImg'/>
                            <div className='featText'>
                                <p>Sign into your brokerage securely without having to give us your account information</p>
                            </div>
                            <a href='#brokerageDetails'><button className="featCardButton">Learn More</button></a>
                        </div>

                    </div>
                </div>
                <div className='inverseBorder'><div className='cutoutLeftBottom easyHide'></div><div className='cutoutRightBottom easyHide'></div></div>
            </div>*/}

            <div className="topContent">
                <div className='topLeftLogo'>
                    <img src={MainLogo} style={{maxHeight:'200px',maxWidth:'250px'}} alt='main logo'></img>
                </div>
                <div className='centerText'>
                    <p>AI Investing Bot for you</p>
                </div>
                <div className='loginButtons'>
                    <Link to='/createAccount'>
                        <button className='topContentButton'>Signup</button>
                    </Link>
                    <Link to='/login'>
                        <button className='topContentButton'>Login</button>
                    </Link>
                </div>
            </div>


            {/*<div className='midContent'>
                <div className='largeContent'>
                    <div className='content1'>

                    </div>
                </div>
                <div className='largeContent'>
                    <div className='content2'>

                    </div>
                </div>
                <div className='dontTellAnyone'>
                    <div className='dontText'>
                        <p>Dont tell anyone</p>
                    </div>
                </div>
            </div>*/}


            <div className='midContent'>

                <div className='content'>
                    <div className='centerWhiteContent centerContentChild'>

                    </div>
                </div>
                <Fade right big>
                    <div className='content'>
                        <div className='centerGreyContent centerContentChild'>

                        </div>
                    </div>
                </Fade>
                <Fade left big>
                    <div className='content'>
                        <div className='centerGoldContent centerContentChild'>
                            
                        </div>
                    </div>
                </Fade>
                <Fade right big>
                <div className='content'>
                    <div className='centerBlackContent centerContentChild'>

                    </div>
                </div>
                </Fade>
                <Fade>
                <div className='largeContent'>
                    <div className='content1'>
                        <div className='leftContent'>
                        </div>
                        <div className='rightContent'>
                            <h2>Enter our universe simulation</h2>
                            <p>Check information on specific stocks and gain insight into them</p>
                        </div>
                    </div>
                </div>
                </Fade>
                <div className='dontTellAnyone'>
                    <div className='dontText'>
                        <p>Please don't tell</p>
                    </div>
                </div>
            </div>
            
            <div className='botContent'>
                <div className='footerLeft'>
                    <p>Left Side</p>
                </div>
                <div className='footerCenter'>
                    <img src={footerLogo} className='footerImg' alt='footer logo'></img>
                </div>
                <div className='footerRight'>
                    <p>Right Side</p>
                </div>
            </div>
        </div>
    )
}

export default MainPage;