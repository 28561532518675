import SideMenu from './settingsSideMenu';

const styleSheet = () => ({
    landingDiv: {
        width:'100vw',
        height:'100vh',
        backgroundColor:'rgb(200,20,125)'
    },
});


const SettingsPayment = () => {
    const styles = styleSheet();

    return (
        <div style={styles.landingDiv}>
            <SideMenu/>
        </div>
    )
}

export default SettingsPayment;