import React, {useState, useEffect} from 'react';
import { socket } from '../service/socket';
import { userSession } from '../service/session';
import './dashboard.css'

const styles = () => ({
    portfolioCont: {
        display:'inline', 
        maxWidth:'-moz-fit-content', 
        maxWidth:'fit-content', maxHeight:'100%', 
        position:'relative',
        top:'2%',
        overflowY:'scroll'
    },
    portfolio: {
        display:'inline', 
        maxWidth:'-moz-fit-content', 
        maxWidth:'fit-content', maxHeight:'100%', 
        position:'relative',
        top:'2%',
        overflowY:'scroll'
    },
    stickyPortfolioTableHeader: {
        position: 'sticky',
        top: '0px',
        backgroundColor: 'purple'
    },
    barometerMeter: {
        height: '50px',
        width: '300px',
    }
    
})


const DashboardPortfolio = props => {
    const name = 'Portfolio'
    const [dataLoaded,setDataLoaded] = useState(false)
    const [accountPortfolio, setAccountPortfolio] = useState([])
    const style = styles();

    useEffect(() => {
        socket.emit("/account/accountPortfolio", userSession.token, (data) => {
            setAccountPortfolio([])
            data.forEach(element => {
                setAccountPortfolio(accountPortfolio => [...accountPortfolio,element])
            })
            setDataLoaded(true)
        });
    }, [])

    useEffect(() => {
        if (dataLoaded) {
            props.onMount(name)
        }
    }, [dataLoaded])

    const breakDownMore = (element) => {
        return Object.entries(element).map(([key,value]) => {        
            return (
                <th key={key}>
                    {isNaN(value) ? value : Math.round(value * 100) / 100}
                </th>
            )
        })
    }

    const garb = (stocks) => {
        return stocks.map(element => {
            return (
                <tr>
                    {breakDownMore(element)}
                </tr>
            )
        });
    }

    const portfolio = () => {
        if (accountPortfolio.length > 0){
            return (
                <div className="portfolioTable" style={style.portfolioCont}>
                    <table style={style.portfolio}>
                        <tbody>
                            <tr>
                                <th style={style.stickyPortfolioTableHeader}>Symbol</th>
                                <th style={style.stickyPortfolioTableHeader}>Avg Price</th>
                                <th style={style.stickyPortfolioTableHeader}>Qty</th>
                                <th style={style.stickyPortfolioTableHeader}>Market Val</th>
                                <th style={style.stickyPortfolioTableHeader}>Current Price</th>
                                <th style={style.stickyPortfolioTableHeader}>% Change</th>
                                <th style={style.stickyPortfolioTableHeader}>Indicator</th>
                            </tr>
                            {garb(accountPortfolio)}
                        </tbody>                           
                    </table>
                </div> 
            )
        }
    }

    return (
        <div className="accountPortfolio contentCard">
            <h4>Open Positions</h4>
            <div className="portfolioNames">
                {portfolio()}
            </div>
        </div>
    )
}

export default DashboardPortfolio;