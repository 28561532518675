import React from "react";
import {Link} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import image from '../videos/logos/Favicon.png'
import LoginThree from "../threeJS/loginThreeScene";
import GoogleConnect from "../oauthConnections/GoogleConnect";

const useStyles = makeStyles((theme) => ({

  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    textDecordation: 'none',
  },
  image: {
    height: '100vh',
    width: '100vw',
    display: "flex",
  },
  login: {
    zIndex: "1",
    height: '55%',
    width: '30%',
    minWidth: "230px",
    minHeight: "500px",
    borderRadius: 10,
    backgroundColor: 'rgb(50, 58, 66)',
    position: "fixed",
    top: "50%",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '50px',
    height: '50px',
  },
  form: {
    width: '70%', // Fix IE 11 issue.
  },
  submit: {
    margin: '3% 0 1% 0',
    backgroundColor: 'rgb(95, 158, 160)',
    '&:hover': {
      backgroundColor: 'gray',
    }
  },
  link: {
    margin: '1% 0 3% 0',
    textDecoration: "none",
    color: 'rgb(95, 158, 160)',
    '&:hover': {
      color: 'gray',
    },
  }
}));

const LoginScreen = () => {
    const classes = useStyles();

      return (
        <div className={classes.root}>
          <div className={classes.image}>
            <LoginThree/>
            <div className={classes.login}>
              <Avatar className={classes.avatar} src={image} component={Link} to={'/'}/>
              <h3>Sign In</h3>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
                <Grid container >
                  <Grid item xs>
                    <Link to="forgotPassword" className={classes.link}>
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/createAccount" className={classes.link}>
                      {"Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form>

              <GoogleConnect />

            </div>
          </div>          
        </div>
      );
    
}



export default LoginScreen;
