import { useHistory } from 'react-router-dom';
import BackArrow from './../videos/svgIcons/backArrow.svg';

const styleSheet = () => ({
    landingDiv: {
        width:'100vw',
        height:'100vh',
        backgroundColor:'rgb(200,250,125)'
    },
    sideMenu: {
        float: 'left',
        left:'0px',
        zIndex: '5',
        width: '20vw',
        height: '100%',
        backgroundColor: 'rgb(50,50,50)'
    },
    sideMenuUL: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: '0px',
        left: '0px',
        paddingTop: '30%',
    },
    sideMenuLI: {
        left:'0px',
        textAlign: 'center',
        listStyle:'none',
        "&:hover": {
            backgroundColor:'rgb(30,30,30)'
        }
    },
    liButton: {
        width: '100%',
        textTransform: 'uppercase',
        outline: 'none',
        border: '0',
        backgroundColor: '#673a9a',
        lineHeight: '40px',
        color: '#fff',   
        display: 'inline-block',
        fontSize: '16px',
        margin: '10px',
        cursor: 'pointer',
        marginLeft:'0px',
    },
    backArrowButton: {
        backgroundColor: 'transparent',
        border: '0px',
        position: 'relative',
        top:'5%',
        left: '35%',
    },
    backArrowStyle: {
        width: '50px',
        height: '50px',
    }
});

const SideMenu = () => {
    const styles = styleSheet();
    const history = useHistory();

    const redirectToInformation = () => {
        history.push('/settings/information')
    }
    const redirectToConnections = () => {
        history.push('/settings/connections')
    }
    const redirectToPayments = () => {
        history.push('/settings/billing')
    }
    const redirectToSupport = () => {
        history.push('/settings/support')
    }
    const redirectToAbout = () => {
        history.push('/settings/about')
    }

    const redirectToDashBoard = () => {
        history.push('/dashboard')
    }

    return (
    <div style={styles.sideMenu}>
        <button style={styles.backArrowButton}><img onClick={redirectToDashBoard} src={BackArrow} style={styles.backArrowStyle} alt='return to dashboard'/></button>
        <ul style={styles.sideMenuUL}>
            <li style={styles.sideMenuLI}>
                <button style={styles.liButton} onClick={redirectToInformation}>Information</button>
            </li>
            <li style={styles.sideMenuLI}>
                <button style={styles.liButton} onClick={redirectToConnections}>Connections</button>
            </li>
            <li style={styles.sideMenuLI}>
                <button style={styles.liButton} onClick={redirectToPayments}>Payments and Billing</button>
            </li>
            <li style={styles.sideMenuLI}>
                <button style={styles.liButton} onClick={redirectToSupport}>Help and Support</button>
            </li>
            <li style={styles.sideMenuLI}>
                <button style={styles.liButton} onClick={redirectToAbout}>About</button>
            </li>
        </ul>
    </div>
    )
}

export default SideMenu;