import React, { useState } from "react";
import AlpacaConnect from "../oauthConnections/AlpacaConnect";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";

const styles = () => ({
    pageDiv: {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'blue',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    connectionDiv: {
        width: '40%',
        height: '60%',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '40px',
    },
    connection: {
        width:'90%',
        minHeight: '10%',
        maxHeight: '15%',
        margin: '5%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    oauthButtons: {
      width: '30%',
      height: '5%',
      margin: '0 0 0 0',
      backgroundColor: 'rgb(95, 158, 160)',
      border: '0px',
    },
    oauthButtonsImgs: {
      width: '15%',
      height: 'auto',
      float: 'left',
      left: '0px',
    }
  });


const AccountSetup = () => {
    const style = styles();
    const [skipToDash, setSkipToDash] = useState(false)

    const changeSkipToDash = () => {
        console.log("Skipping")
        setSkipToDash(true)
    }

    if(skipToDash) {
        return <Redirect to='/dashboard' />
    }

    return (
        <div style={style.pageDiv}>
            <div style={style.connectionDiv}>
                <h1>Please connect your brokerage account</h1>
                <div style={style.connection}>
                    <AlpacaConnect forSetup={true} />
                </div>
                <div style={style.connection}>
                    <Button>Continue with Fidelity</Button>
                </div>
                <div style={style.connection}>
                    <Button>Continue with ETrade</Button>
                </div>
                <div style={style.connection}>
                    <Button onClick={() => changeSkipToDash()}>Skip for Now</Button>
                </div>
            </div>
        </div>
    )
}

export default AccountSetup;