import React, { useState } from "react";
import googleFav from '../videos/loginIcons/googleFav2.png';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {socket} from '../service/socket';
import {userSession} from '../service/session'

<script src="https://apis.google.com/js/platform.js" async defer></script>


const nonMakeStyles = () => ({
    oauthButtons: {
      width: '30%',
      height: '5%',
      margin: '0 0 0 0',
      backgroundColor: 'rgb(95, 158, 160)',
      border: '0px',
      fontSize: '8px'
    },
    oauthButtonsImgs: {
      width: '15%',
      height: 'auto',
      float: 'left',
      left: '0px',
    }
  });


const GoogleConnect = () => {
  const nonMakeStylesVar = nonMakeStyles();
  const [redirectToDash,setRedirectToDash] = useState(false)
  const [redirectToSetup,setRedirectToSetup] = useState(false)
  
  const sendGoogleTokenToBackend = (token,id) => {
    console.log("Sending to backend")
    socket.emit("/api/googleOAUTH", token, (data) => {
      if (data['result'] === null) {
        userSession.handleUnsuccessfulLogin()
        alert("Google login failed on our end most likely, try again")
        return
      }

      userSession.handleSuccessfulLogin(data['result']['session'])
      if ('newAccount' in data['result']) {
        setRedirectToSetup(true)
        setRedirectToDash(false)
      } else {
        setRedirectToDash(true)
      }

    });
  }


  async function ConnectToGoogle() {
    socket.emit('/api/google/OAUTH', (data) => {
      console.log(data)
      beginFlow(data)
    })
  }

  const beginFlow = async url => {
    if (url != null){
      try {
        const ret = await openGooglePopup(url)
        socket.emit('/api/google/verifyToken', ret, (data) => {
          if (data['result'] === null) {
            userSession.handleUnsuccessfulLogin()
            alert("Google login failed on our end most likely, try again")
            return
          }
    
          userSession.handleSuccessfulLogin(data['result']['session'])
          if ('newAccount' in data['result']) {
            setRedirectToSetup(true)
            setRedirectToDash(false)
          } else {
            setRedirectToDash(true)
          }
        })
      }
      catch (e) {
        const error = {};
        error.message = e.message;
        alert(error);
        console.log(error);
      }
    } else {
      console.log(url)
    }
  }

  const openGooglePopup = (url) => {
    return new Promise((resolve,reject) => {
      const authWindow = window.open(url, "Google Login for Superbrain",'width=400,height=500')
      let snippet = url | null
      const interval = setInterval(async () => {
        try {
          snippet = authWindow && authWindow.location && authWindow.location.search;
        } catch (e) {}
        if (snippet) {
          const rawCode = snippet
          authWindow.close()
          resolve(rawCode);
          clearInterval(interval);
        }
      }, 100)
    })
  }

  if (redirectToSetup) {
    return <Redirect to='/account/setup' />
  }
  if (redirectToDash) {
    return <Redirect to='/dashboard' />
  } 

  return (
    <Button onClick={ConnectToGoogle} style={nonMakeStylesVar.oauthButtons}>
      <img src={googleFav} style={nonMakeStylesVar.oauthButtonsImgs} alt='Google login button'/>
      Continue with Google
      </Button>
  )  
}

export default GoogleConnect;